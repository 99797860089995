.cardBtn {
  border: 1px solid var(--color-text-primary);
  background: var(--neutral-white, #fff);
  border-radius: 4px;
  box-shadow: 0 1px 2px -1px rgba(17, 12, 34, 8%);
  padding: 14px 1rem;
  height: 44px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.swiperButton {
  position: absolute;
  top: -48px;
  right: 0;
}

.scrollbarContainer {
  display: block;
  left: 0 !important;
  right: 15px;

  @media screen and (--mobile-xs) {
    width: calc(100% - 7 * var(--swiper-scrollbar-sides-offset, 1%)) !important;
  }

  @media screen and (--mobile) {
    width: calc(
      100% - 4.5 * var(--swiper-scrollbar-sides-offset, 1%)
    ) !important;
  }

  @media screen and (--tablet-s) {
    width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%)) !important;
  }
}

.marketingTile > .scrollbarContainer {
  display: block;
  height: 5px !important;

  @media screen and (--desktop) {
    .scrollbarContainer {
      display: none;
    }
  }
}

.marketingTileSlide {
  max-width: 230px;
  @media screen and (--desktop) {
    max-width: 270px;
  }

  @media (min-width: 1024px) and (max-width: 1180px) {
    max-width: 230px;
  }
  @media screen and (--tablet) {
    max-width: 217px;
  }
  @media screen and (--mobile) {
    min-width: 188px;
    max-width: 190px;
  }
}

.draggableElement {
  background: var(--color-text-primary);
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  left: 0;
  top: 0;
}

.projectCardHeadingWrapper .title {
  --typography-heading-4-font-size: var(
    --typography-display-large-desktop-font-size
  );
  --typography-heading-4-line-height: var(
    --typography-display-large-desktop-line-height
  );
}

.categoryCardSwiperRoot :global(.swiper-slide) {
  width: 354px;
}

.projectCardSwiperRoot :global(.swiper-slide) {
  width: 428px;
}

@media screen and (--mobile) {
  .projectCardSwiperRoot :global(.swiper-slide),
  .categoryCardSwiperRoot :global(.swiper-slide) {
    width: 253px;
  }
}

@media screen and (--desktop) {
  .wrapperContainer {
    display: grid;
    grid-template-columns: 34% 66%;
  }

  .desktopCarouselDisabledCard {
    min-height: 172px !important;
  }
}

@media screen and (--desktop-l) {
  .cardsContainer {
    padding-right: 188px;
  }
}

.root {
  --section-spacing-default: 0;
}

.root :global(.swiper) {
  margin: 0;
}
